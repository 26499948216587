@import '../../variables.scss';

.deploy {
  &--deploy-ipc-container {
    z-index: 500;
    height: 100%;
    width: 100%;
    font: $main-font;
    font-size: 12px;
    font-family: $main-font;
    background-color: $darker-background-grey;
    padding: 0 0 20px 40px;
    border: none;

    .ipc-header {
      color: #fff;
      font-size: 12px;
      letter-spacing: 0.5px;
      margin: 15px 0;
    }

    .deploy-ipc {
      min-height: 50px;
      display: flex;
      align-items: center;

      .deploy-ipc-input,
      .deploy-ipc-select {
        width: 180px;
        height: 40px;
        color: $white;
        padding-left: 10px;
        background-color: $darker-background-grey;
        border: solid 2px $light-grey;
        border-radius: $small-border-radius;
        margin: 5px 25px 5px 0;
      }

      .deploy-ipc-input:focus,
      .deploy-ipc-select:focus {
        outline: none;
        border: solid 2px $orange;
      }

      .deploy-ipc-button {
        width: 75px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 16px;
        color: $white;
        letter-spacing: 0.25px;
        border: none;
        border-radius: $small-border-radius;
        background-color: $orange;
      }

      .deploy-ipc-button:hover {
        cursor: pointer;
        background-color: $light-orange;
      }

      .deploy-ipc-button:disabled {
        cursor: default;
        background-color: $dark-grey;
        color: $darker-background-grey;
      }

      .deploy-ipc-error-message {
        display: flex;
        align-items: center;
        color: $red;
        margin: auto 25px;
      }
    }
  }
}

@media (min-width: $large-screen-width) {
  .deploy {
    &--deploy-ipc-container {
      font-size: 14px;
      transition: $transition;

      .ipc-header {
        font-size: 14px;
        transition: $transition;
      }

      .deploy-ipc {
        .deploy-ipc-input,
        .deploy-ipc-select {
          width: 340px;
          transition: $transition;
        }

        .deploy-ipc-button {
          width: 100px;
          transition: $transition;
        }
      }
    }
  }
}

@media (min-width: $largest-screen-width) {
  .deploy {
    &--deploy-ipc-container {
      .deploy-ipc {
        flex-direction: row;
      }
    }
  }
}
