@import '../../variables.scss';

.deploys-table {
  &--deploys-container {
    width: 90%;
    height: 100%;
    font: var(--main-font);
    font-size: 12px;
    font-family: var(--main-font);
    background-color: var(--darker-background-grey);

    .deploy-ipc-header {
      height: 20px;
      width: 100%;
      color: #fff;
      margin: 25px 0 15px 0;
    }

    .margin-right {
      margin-right: auto;
    }

    .hover:hover {
      cursor: pointer;
    }

    .left {
      align-self: flex-start;
    }

    .dark-hover:hover {
      background-color: var(--dark-background-grey);
    }

    .light-hover {
      width: 36px;
      height: 36px;
      display: grid;
      place-items: center;
      border-radius: var(--small-border-radius);
    }

    .light-hover:hover {
      cursor: pointer;
      background-color: var(--grey);
    }
  }
}

@media (min-width: $large-screen-width) {
  .deploys-table {
    &--deploys-container {
      font-size: 14px;
      transition: var(--transition);

      .deploy-ipc-header {
        font-size: 14px;
      }
    }
  }
}
