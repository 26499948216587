@import '../../variables.scss';

.list {
  &--ipc-list-container {
    z-index: 500;
    height: 100%;
    width: 100%;
    font: var(--main-font);
    font-family: var(--main-font);
    background-color: var(--darker-background-grey);
    padding: 0 0 20px 40px;
    border: none;

    .ipc-header {
      color: #fff;
      font-size: 12px;
      letter-spacing: 0.5px;
      margin: 15px 0;
    }

    .search-ipc-container {
      height: 50px;
      display: flex;
      align-items: center;

      .search-ipc-input {
        width: 250px;
        height: 40px;
        font-size: 14px;
        color: var(--white);
        padding-left: 10px;
        background-color: var(--darker-background-grey);
        border: solid 2px var(--light-grey);
        border-radius: var(--small-border-radius);
        margin-right: 25px;
      }

      .search-ipc-input:focus {
        outline: none;
        border: solid 2px var(--orange);
      }
    }
  }
}

@media (min-width: $large-screen-width) {
  .list {
    &--ipc-list-container {
      .ipc-header {
        font-size: 14px;
      }

      .search-ipc-container {
        .search-ipc-input {
          width: 340px;
          transition: var(--transition);
        }
      }
    }
  }
}
