@import './variables.scss';

.container {
  .ant-spin {
    margin: 40px;
  }
}

.ant-spin-dot-item {
  background-color: $orange !important;
}

.deploy-ipc-button {
  .ant-spin-dot-item {
    background-color: black !important;
  }
}
