@import '../../variables.scss';

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  margin: 0;

  .table-row,
  .table-header-row {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: $white;
    border-bottom: solid 1px $dark-grey;

    .table-row-item-container {
      width: 125px;
      display: flex;
      align-items: center;
      justify-content: center;

      .table-row-item {
        width: 100px;
        height: 26px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 12px;

        .table-row-success-message,
        .table-row-error-message {
          margin-left: 5px;
          color: $green;
        }

        .table-row-error-message {
          color: $red;
        }

        .table-row-error-message {
          color: $orange;
        }
      }

      .table-row-item-large-width {
        display: grid;
        place-items: center;
      }

      .download-status {
        display: flex;
        align-items: center;
      }
    }
  }

  .table-header-row {
    background-color: $dark-background-grey;
    border-radius: 3px;
  }

  .red-row {
    background-color: rgba(240, 22, 40, 0.25);
  }
}

@media (min-width: $large-screen-width) {
  .table {
    .table-row,
    .table-header-row {
      .table-row-item-container {
        margin-right: 40px;
        transition: $transition;

        .table-row-item {
          font-size: 14px;
          transition: $transition;
        }
      }
    }
  }
}

@media (min-width: $largest-screen-width) {
  .table {
    .table-row,
    .table-header-row {
      .table-row-item-container {
        margin-right: 120px;
        transition: $transition;
      }
    }
  }
}
