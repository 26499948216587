@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$white: #fff;
$light-grey: #e2e4e4;
$dark-grey: #666;
$background-grey: #8b8989;
$dark-background-grey: #3c3c3c;
$darker-background-grey: #18191a;
$orange: #f05921;
$light-orange: #f09a2e;
$red: #f01628;
$light-red: #f8636f;
$blue: #076ea3;
$light-blue: #16a5f0;
$green: #27a344;
$light-green: #2ef05b;
$purple: #6f16f0;
$teal: #0af0c2;
$main-font: 'Roboto', sans-serif, Arial, Helvetica;
$small-border-radius: 5px;
$large-border-radius: 10px;
$small-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$large-box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 24px;
$large-screen-width: 768px;
$largest-screen-width: 1080px;
$transition: ease all 0.25s;
