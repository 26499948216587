@import '../../variables.scss';

.ipc-fullscreen-loader {
  width: 100%;
  height: 500px;
  margin: 50px;
  color: $orange;

  .ant-spin-text {
    color: $orange;
  }

  .ant-spin-dot-item {
    background-color: $orange;
  }
}
