@import '../../variables.scss';

.recent-deploys-table {
  &--recently-added-container {
    width: 90%;
    height: 100%;
    font: $main-font;
    font-size: 12px;
    font-family: $main-font;
    background-color: $darker-background-grey;

    .recently-added-header {
      width: 100%;
      height: 20px;
      color: #fff;
      margin: 25px 0 15px 0;
    }

    .left {
      align-self: flex-start;
    }

    .dark-hover:hover {
      background-color: $dark-background-grey;
    }

    .light-hover {
      width: 36px;
      height: 36px;
      display: grid;
      place-items: center;
      border-radius: $small-border-radius;
    }

    .light-hover:hover {
      cursor: pointer;
      background-color: $dark-grey;
    }
  }
}

@media (min-width: $large-screen-width) {
  .recent-deploys-table {
    &--recently-added-container {
      font-size: 14px;
      transition: $transition;

      .recently-added-header {
        font-size: 14px;
        transition: $transition;
      }
    }
  }
}
