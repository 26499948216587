@import '../../variables.scss';

.side-menu {
  overflow-y: hidden !important;

  &--side-menu-container,
  &--side-menu-container-closed {
    height: calc(100vh - 40px);
    width: 300px;
    z-index: 100;
    font-family: var(--main-font);
    background-color: var(--dark-background-grey);
    transition: var(--transition);

    .side-menu {
      height: 100%;
      width: 300px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .side-menu-header,
      .side-menu-header-closed {
        width: 200px;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        transition: var(--transition);
      }

      .side-menu-header {
        width: 100px;
        margin: 20px auto;
      }

      .side-menu-header-closed {
        width: 100px;
      }

      .side-menu-content,
      .side-menu-content-closed {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        transition: var(--transition);

        .side-menu-options {
          width: 100%;

          .displayed-option,
          .displayed-option-active,
          .displayed-option-closed,
          .displayed-option-closed-active {
            height: 40px;
            display: flex;
            align-items: center;
            text-decoration: none;
            background-color: var(--dark-background-grey);
            transition: var(--transition);
          }

          .displayed-option,
          .displayed-option-active {
            width: 300px;

            .displayed-option-title,
            .displayed-option-icon,
            .displayed-option-icon {
              color: var(--white);
              letter-spacing: 0.5px;
              margin: 0 5px;
              transition: var(--transition);
            }

            .displayed-option-icon {
              color: var(--white);
              letter-spacing: 0.5px;
              margin: 0 5px 0 25px;
            }

            .displayed-option-icon-closed {
              margin: 0 5px 0 5px;
              transition: var(--transition);
            }
          }

          .displayed-option-closed,
          .displayed-option-closed-active {
            width: 100px;
            display: grid;
            place-items: center;
          }

          .displayed-option-active,
          .displayed-option-closed-active {
            background-color: var(--grey);
          }

          .displayed-option:hover,
          .displayed-option-closed:hover {
            background-color: var(--light-orange);
          }

          .displayed-option:hover,
          .displayed-option-closed:hover,
          .side-menu-toggle:hover,
          .side-menu-toggle-closed:hover {
            cursor: pointer;
            background-color: var(--light-orange) !important;
          }
        }

        .side-menu-toggle {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 50px;
          width: 300px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background-color: var(--orange);
          transition: var(--transition);

          &:hover {
            cursor: pointer;
            background-color: $light-orange;
          }
        }
      }

      .side-menu-content-closed {
        margin-left: 0;

        .side-menu-toggle-closed {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 50px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background-color: var(--orange);
          transition: var(--transition);
          &:hover {
            cursor: pointer;
            background-color: $light-orange;
          }
        }
      }
    }
  }

  &--side-menu-container-closed {
    width: 100px;
  }
}
