@import '../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
}

.flex {
  flex-direction: row;
}
