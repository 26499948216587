@import '../../variables.scss';

.not-found {
  &--not-found-container {
    width: 85%;
    margin: 40px;

    .not-found-text {
      color: var(--white);
      font-size: 42px;
      letter-spacing: 1px;
    }

    .not-found-face {
      font-size: 70px;
      font-weight: bold;
      color: var(--orange);
      margin-top: 50px;
    }
  }
}
